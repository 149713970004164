@use "sass:math";
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css2?family=Shadows+Into+Light&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: white;
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: white;
}

.fantasy-view-button {
  background-color: #8b0000; /* Dark red background color */
  border: none;
  color: #ffffff; /* White text color */
  padding: 10px 20px; /* Padding around the button text */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px; /* Rounded corners */
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  background-image: linear-gradient(to bottom, #8b0000 0%, #6b0000 100%); /* Gradient background */
  border: 1px solid #5a0000; /* Dark red border */
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5); /* Text shadow */
  transition: background-color 0.3s ease; /* Smooth transition effect for hover */
}

.fantasy-view-button:hover {
  background-color: #a00000; /* Darker red color on hover */
}

// Dice Roller----------------------------------------------------------------------------------------------
$containerWidth: 120px;
$containerHeight: $containerWidth;

$faceWidth:  $containerWidth*0.5;
$faceHeight: $faceWidth*0.86;

$transitionDuration: 0.5s;
$animationDuration:  3s;

$angle: 53deg;
$ringAngle: -11deg;
$sideAngle: calc(360deg / 5);
$color: var(--dice-face-color);

$rotateX: -$angle;
$translateZ: $faceWidth*0.335;
$translateY: -$faceHeight*0.15;
$translateRingZ: $faceWidth*0.75;
$translateRingY: $faceHeight*0.78 + $translateY;
$translateLowerZ: $translateZ;
$translateLowerY: $faceHeight*0.78 + $translateRingY;

@keyframes roll {
  10% { transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) }
  30% { transform: rotateX(120deg) rotateY(240deg) rotateZ(0deg) translateX(40px) translateY(40px) }
  50% { transform: rotateX(240deg) rotateY(480deg) rotateZ(0deg) translateX(-40px) translateY(-40px) }
  70% { transform: rotateX(360deg) rotateY(720deg) rotateZ(0deg) }
  90% { transform: rotateX(480deg) rotateY(960deg) rotateZ(0deg) }
}

.content {
  margin: auto auto;
  position: relative;
  width: $containerWidth;
  height: $containerHeight;
  perspective: 1500px;
}

.die {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform $transitionDuration ease-out;
  cursor: pointer;
  
  transform: rotateX($rotateX);
  
  &.rolling {
    animation: roll $animationDuration linear;
  }
  
  @for $i from 1 through 5 {
    &[data-face="#{$i}"] {
      $angleMultiplier: $i - 1;
      transform: rotateX(-$angle) rotateY($sideAngle * $angleMultiplier);
    }
  }
  
  @for $i from 16 through 20 {
    &[data-face="#{$i}"] {
      $angleMultiplier: $i - 15;
      transform: rotateX(-$angle + 180deg) rotateY(-$sideAngle * $angleMultiplier);
    }
  }
  
  @for $i from 6 through 10 {
    &[data-face="#{$i}"] {
      $angleMultiplier: $i - 6;
      transform: rotateX(-$ringAngle) rotateZ(180deg) rotateY($sideAngle * $angleMultiplier);
    }
  }
  
  @for $i from 11 through 15 {
    &[data-face="#{$i}"] {
      $angleMultiplier: $i - 8;
      transform: rotateX(-$ringAngle) rotateY(-$sideAngle * $angleMultiplier - calc($sideAngle / 2));
    }
  }
  
  .face {
    $horizontalMargin: -$faceWidth*0.5;
    
    position: absolute;
    left: 50%;
    top: 0;
    margin: 0 $horizontalMargin;
    border-left: $faceWidth*0.5 solid transparent;
    border-right: $faceWidth*0.5 solid transparent;
    border-bottom: $faceHeight solid $color;
    width: 0px;
    height: 0px;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    
    counter-increment: steps 1;
  
    &:before {
      content: counter(steps);
      position: absolute;
      top: $faceHeight*0.25;
      left: -$faceWidth;
      color: #fff;
      text-shadow: 1px 1px 3px #000;
      font-size: $faceHeight*0.5;
      text-align: center;
      line-height: $faceHeight*0.9;
      width: $faceWidth*2;
      height: $faceHeight;
    }
    
    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        $angleMultiplier: $i - 1;
        transform: rotateY(-$sideAngle * $angleMultiplier) translateZ($translateZ) translateY($translateY) rotateX($angle);
      }
    }
      
    @for $i from 16 through 20 {
      &:nth-child(#{$i}) {
        $angleMultiplier: $i - 18;
        transform: rotateY($sideAngle * $angleMultiplier + calc($sideAngle / 2)) translateZ($translateLowerZ) translateY($translateLowerY) rotateZ(180deg) rotateX($angle);
      }
    }
      
    @for $i from 6 through 10 {
      &:nth-child(#{$i}) {
        $angleMultiplier: $i - 11;
        transform: rotateY(-$sideAngle * $angleMultiplier) translateZ($translateRingZ) translateY($translateRingY) rotateZ(180deg) rotateX($ringAngle);
      }
    }
      
    @for $i from 11 through 15 {
      &:nth-child(#{$i}) {
        $angleMultiplier: $i - 8;
        transform: rotateY($sideAngle * $angleMultiplier + calc($sideAngle / 2)) translateZ($translateRingZ) translateY($translateRingY) rotateX($ringAngle);
      }
    }
  }
}
// Damage display-------------------------------------------------------
#damageAmount {
  width: 70px;
  height: 70px;
  border: 2px solid #8b0000; /* Dark red border */
  border-radius: 50%;
  color: #8b0000; /* Dark red text color */
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  line-height: 80px; /* Center content vertically */
  font-family: 'Courier New', monospace; /* Zombie-themed font */
  position: relative; /* Required for pseudo-element */
  overflow: hidden; /* Hide overflow to enable animation */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-size: 70px;
}

#damageValue {
  padding-top: 5px;
  display: inline-block; /* Make the text an inline block */
  animation: rollAnimation 0.5s ease-in-out; /* Apply animation */
}

/* Prevent the animation on initial load */
#damageAmount:not(:hover) #damageValue {
  animation: none;
}

#damageAmount.loading .spinner {
  display: block;
}

/* Spinner styles */
.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #8b0000; /* Dark red top border */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite; /* Rotate animation */
}

.spinner {
  display: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Hidden class to hide elements */
.hidden {
  display: none !important;
}




/* -------------------------------------------------Media Query---------------------------------------- */
/* Media query for screens smaller than 2160px */
@media (max-width: 2160px) {
  .zombieDMCharacterSelectTable {
    font-size: 13px;
  }
  .zombieCharacterSelectTable {
    font-size: 18px;
  }
  .zombiesActionItem {
    width: 20rem;
  }
  .zombieHealth {
    width: 12rem;
    text-align: center;
  }
  .zombieSkills {
    width: 19rem;
    font-size: 13px;
  }
  .zombiesFeats {
    width: 19rem;
    font-size: 14px;
  }
  .zombiesWeapons {
    width: 20rem;
    font-size: 12px;
  }
  .zombiesArmor {
    width: 20rem;
    font-size: 14px;
  }
  .zombiesItems {
    width: 20rem;
    font-size: 15px;
  }
}

/* Media query for screens smaller than 375px */
@media (max-width: 375px) {
  .zombieDMCharacterSelectTable {
    font-size: 11px;
  }
  .zombieCharacterSelectTable {
    font-size: 13px;
  }
  .zombiesActionItem {
    width: 16rem;
  }
  .zombieHealth {
    width: 12rem;
    text-align: center;
  }
  .zombieSkills {
    width: 18rem;
    font-size: 12px;
  }
  .zombiesFeats {
    width: 17rem;
    font-size: 12px;
  }
  .zombiesWeapons {
    width: 18rem;
    font-size: 11px;
  }
  .zombiesArmor {
    width: 18rem;
    font-size: 13px;
  }
  .zombiesItems {
    width: 18rem;
    font-size: 14px;
  }
}

/* Media query for screens smaller than 320px */
@media (max-width: 320px) {
  .zombieDMCharacterSelectTable {
    font-size: 9px;
  }
  .zombieCharacterSelectTable {
    font-size: 9px;
  }
  .zombiesActionItem {
    width: 14rem;
  }
  .zombieHealth {
    width: 12rem;
    text-align: center;
  }
  .zombieSkills {
    width: 15rem;
    font-size: 9px;
  }
  .zombiesFeats {
    width: 15rem;
    font-size: 11px;
  }
  .zombiesWeapons {
    width: 15rem;
    font-size: 9px;
  }
  .zombiesArmor {
    width: 15rem;
    font-size: 14px;
  }
  .zombiesItems {
    width: 15rem;
    font-size: 11px;
  }
}

//------------------------------------Critical Rolls----------------------------------

@keyframes sparkles {
  0%, 100% {
    opacity: 1;
    transform: translateY(0) rotate(-360deg);
  }
  50% {
    opacity: 1;
    transform: translateY(0) rotate(360deg);
  }
}

.sparkle {
  position: absolute;
  width: 120px;
  height: 120px;
  background: radial-gradient(circle, #38e703, transparent);
  animation: sparkles 1s linear infinite;
}

@keyframes sparkles1 {
  0%, 100% {
    opacity: 1;
    transform: translateY(0) rotate(-360deg);
  }
  50% {
    opacity: 1;
    transform: translateY(0) rotate(360deg);
  }
}

.sparkle1 {
  position: absolute;
  width: 120px;
  height: 120px;
  background: radial-gradient(circle, #df081a, transparent);
  animation: sparkles 1s linear infinite;
}

.center-td {
  text-align: center; /* Center horizontally */
  vertical-align: middle; /* Center vertically */
}

.custom-table {
max-width: 300px;
}

.table-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.fantasy-button {
  background: linear-gradient(135deg, #3e8e41, #2e7d32);
  border: 2px solid #000;
  border-radius: 50px;
  color: #fff;
  font-family: 'Cinzel', serif;
  font-size: 16px;
  padding: 10px 20px;
  text-shadow: 1px 1px 2px #000;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.fantasy-button:hover {
  background: linear-gradient(135deg, #4caf50, #388e3c);
  color: #f8e71c;
  text-shadow: 2px 2px 4px #000;
  transform: scale(1.05);
}

.fantasy-button .icon {
  font-size: 18px;
  margin-right: 8px;
}

.hostCampaign {
  background-color: #ffc107 !important; /* Yellow color */
  color: black !important;
  border: none;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
}

.hostCampaign .icon {
  margin-right: 8px;
}

.hostCampaign:hover {
  background-color: #e0a800 !important;
  text-shadow: 2px 2px 4px #fff;
  transform: scale(1.05);
}

.dnd-background {
  background-color: rgba(0, 0, 0, 0.8) !important; /* Semi-transparent black */
  border: none; /* Remove border */
  color: #fff !important; /* Text color */
  font-family: 'Cinzel', serif; /* Font family */
  text-shadow: 1px 1px 2px #000; /* Text shadow */
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2); /* Shadow effect */
  border-radius: 10px; /* Rounded corners */
}

.dnd-background table,
.dnd-background th,
.dnd-background td {
  color: #fff !important; /* Ensure text color is white */
}

.dnd-modal .modal-content {
  background-color: transparent !important; /* Make the modal content area transparent */
  box-shadow: none !important; /* Remove any box shadow */
  border: none !important; /* Remove any border */
}

//vars
$fg: linear-gradient(135deg, #3e8e41, #2e7d32);
$fgDM: linear-gradient(135deg, #979940b6, #fffb00);
$bg: linear-gradient(135deg, #3e8e41, #2e7d32);
$pi: 3.14;

//config
$menu-items: 2;
$menuDM-items: 3;
$open-distance: 115px;
$opening-angle: $pi - 0.2;

body {
  background: $bg;
  color: white;
  text-align: center; // Ensure text is centered horizontally
}

a {
  color: inherit;
}

h1, h2, h3, h4 {
  margin: 0;
  margin-bottom: 10px;
  margin-top: 10px;
}

h1 {
  font-size: 3em;
}

%goo {
  filter: url('#shadowed-goo');
  // debug 
  // background:rgba(255,0,0,0.2);
}

%ball {
  background: $fg;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 300px; // Adjust as needed
  left: 160px; // Adjust as needed
  right: 20px;
  color: white;
  text-align: center;
  line-height: 80px;
  transform: translate3d(0, 0, 0);
  transition: transform ease-out 200ms;
}

%ballDM {
  background: $fgDM;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 300px; // Adjust as needed
  left: 160px; // Adjust as needed
  right: 20px;
  color: white;
  text-align: center;
  line-height: 80px;
  transform: translate3d(0, 0, 0);
  transition: transform ease-out 200ms;
}

.menu-open {
  display: none;
}

.menuDM-open {
  display: none;
}

.menu-item {
  @extend %ball;
}

.menuDM-item {
  @extend %ballDM;
}

.hamburger {
  width: 25px;  // Define width directly here
  height: 3px;  // Define height directly here
  background: white;
  display: block;
  position: absolute;
  top: 50%; // Center vertically
  left: 30%; // Center horizontally
  transform: translate(-50%, -50%);
  transition: transform 200ms;
}

.hamburger-1, .hamburger-3 {
  position: absolute;
  width: 25px;  // Define width directly here
  height: 3px;  // Define height directly here
  background: white;
}

.hamburger-1 {
  transform: rotate(90deg);
}

.hamburger-2 {
  transform: rotate(0deg);
}

.hamburger-3 {
  transform: rotate(0deg);
}

.menu-open:checked + .menu-open-button {
  .hamburger-1 {
    transform: rotate(45deg);
  }
  .hamburger-2 {
    display: none; // Hide the middle bar when checked
  }
  .hamburger-3 {
    transform: rotate(-45deg);
  }
}

.menuDM-open:checked + .menuDM-open-button {
  .hamburger-1 {
    transform: rotate(45deg);
  }
  .hamburger-2 {
    display: none; // Hide the middle bar when checked
  }
  .hamburger-3 {
    transform: rotate(-45deg);
  }
}

.menu {
  @extend %goo;
  $width: 380px;
  $height: 250px;
  position: absolute;
  left: 50%; // Center horizontally
  top: 50%; // Center vertically
  transform: translate(-50%, -50%); // Centering trick
  padding-top: 20px;
  padding-left: math.div($width, 2);
  width: $width;
  height: $height;
  box-sizing: border-box;
  font-size: 20px;
  text-align: left;
}

.menuDM {
  @extend %goo;
  $width: 380px;
  $height: 250px;
  position: absolute;
  left: 50%; // Center horizontally
  top: 50%; // Center vertically
  transform: translate(-50%, -50%); // Centering trick
  padding-top: 20px;
  padding-left: math.div($width, 2);
  width: $width;
  height: $height;
  box-sizing: border-box;
  font-size: 20px;
  text-align: left;
}

.menu-item {
  &:hover {
    background: white;
    color: $fg;
  }
  @for $i from 1 through $menu-items {
    &:nth-child(#{$i + 2}) {
      transition-duration: 10ms + (60ms * ($i));
    }
  }
}

.menuDM-item {
  &:hover {
    background: white;
    color: $fgDM;
  }
  @for $i from 1 through $menuDM-items {
    &:nth-child(#{$i + 2}) {
      transition-duration: 10ms + (60ms * ($i));
    }
  }
}

.menu-open-button {
  @extend %ball;
  z-index: 2;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);
  transition-duration: 400ms;
  transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  cursor: pointer;
}

.menuDM-open-button {
  @extend %ballDM;
  z-index: 2;
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1.275);
  transition-duration: 400ms;
  transform: scale(1.1, 1.1) translate3d(0, 0, 0);
  cursor: pointer;
}

.menu-open-button:hover {
  transform: scale(1.2, 1.2) translate3d(0, 0, 0);
}

.menuDM-open-button:hover {
  transform: scale(1.2, 1.2) translate3d(0, 0, 0);
}

.menu-open:checked + .menu-open-button {
  transition-timing-function: linear;
  transition-duration: 200ms;
  transform: scale(0.8, 0.8) translate3d(0, 0, 0);
}

.menuDM-open:checked + .menuDM-open-button {
  transition-timing-function: linear;
  transition-duration: 200ms;
  transform: scale(0.8, 0.8) translate3d(0, 0, 0);
}

.menu-open:checked ~ .menu-item {
  transition-timing-function: cubic-bezier(0.935, 0.000, 0.340, 1.330);
  @for $i from 1 through $menu-items {
    $angle: math.div(($pi - $opening-angle), 2) + (math.div($opening-angle, ($menu-items - 1)) * ($i - 1));
    $x: math.cos($angle) * $open-distance;
    $y: math.sin($angle) * $open-distance;
    
    &:nth-child(#{$i + 2}) {
      transition-duration: 80ms + (80ms * $i);
      transform: translate3d($x, $y, 0);
    }
  }
}

.menuDM-open:checked ~ .menuDM-item {
  transition-timing-function: cubic-bezier(0.935, 0.000, 0.340, 1.330);
  @for $i from 1 through $menuDM-items {
    $angle: math.div(($pi - $opening-angle), 2) + (math.div($opening-angle, ($menuDM-items - 1)) * ($i - 1));
    $x: math.cos($angle) * $open-distance;
    $y: math.sin($angle) * $open-distance;
    
    &:nth-child(#{$i + 2}) {
      transition-duration: 80ms + (80ms * $i);
      transform: translate3d($x, $y, 0);
    }
  }
}

.create-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #3e8e41 !important; /* Green background */
  border: none !important;
  color: white;
  border-radius: 50% !important; /* Makes the button circular */
  width: 65px; /* Ensure width and height are the same */
  height: 65px; /* Ensure width and height are the same */
  position: relative;
  padding: 0; /* Remove padding to maintain the circular shape */
  overflow: hidden; /* Ensures the circular shape */
  transition: transform 0.2s, background-color 0.2s;
}

.create-button:hover {
  background-color: #45a049 !important; /* Darker green on hover */
  transform: scale(1.1); /* Slightly enlarge the button on hover */
}

.create-button:active {
  background-color: #2e7d32 !important; /* Even darker green on click */
  transform: scale(0.9); /* Slightly shrink the button on click */
}

.fa-plus {
  font-size: 24px; /* Adjust as needed */
  color: white;
}

@keyframes pulseOnce {
  0% {
    background-color: rgba(255, 0, 0, 0.8);
    transform: scale(1);
  }
  50% {
    background-color: rgba(255, 0, 0, 0.4);
    transform: scale(1.1);
  }
  100% {
    background-color: rgba(255, 0, 0, 0.8);
    transform: scale(1);
  }
}

#damageAmount {
  transition: background-color 0.5s ease, transform 0.5s ease;
}

.pulse {
  animation: pulseOnce 2s 1; /* Run the animation once over 2 seconds */
}

.hidden {
  display: none;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Modern Modal */
.modern-modal .modal-content-wrapper {
  animation: scaleUp 0.2s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Card Styling */
.modern-card {
  width: 100%;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  background: #f8f9fa;
  overflow: hidden;
}

/* Modal Header */
.modal-header {
  background: linear-gradient(135deg, #4CAF50, #388E3C);
  color: white;
  text-align: center;
  padding: 10px;
}

.modal-title {
  font-size: 1.4rem;
  font-weight: bold;
}

/* Points Section */
.points-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.points-label {
  font-weight: bold;
  color: #444;
}

.points-value {
  margin-left: 8px;
  font-weight: bold;
  color: #2e7d32;
}

/* Table Styling */
.modern-table {
  width: 100%;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.modern-table th,
.modern-table td {
  text-align: center;
  padding: 8px;
  min-width: 60px;
}

.modern-table th {
  background: #e0e0e0;
  font-weight: bold;
}

/* Buttons */
.stat-btn {
  border: none;
  padding: 5px 8px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  width: 32px; /* Fixed width */
}

.minus-btn {
  background: #e57373;
  color: white;
  visibility: hidden;
}

.plus-btn {
  background: #4CAF50;
  color: white;
}

.minus-btn.visible {
  visibility: visible;
}

.plus-btn.hidden {
  visibility: hidden;
}

/* Footer Buttons */
.modal-footer {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.action-btn {
  flex: 1;
  padding: 8px;
  font-size: 1rem;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.2s ease-in-out;
}

.save-btn {
  background: #ffc107;
  margin-right: 5px;
}

.close-btn {
  background: #6c757d;
}

.save-btn:hover {
  background: #d39e00;
}

.close-btn:hover {
  background: #545b62;
}
